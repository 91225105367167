<template>
  <div class="wrapper">
    <div class="content-box">
      <router-view />
      <Nav />
    </div>
  </div>
</template>

<script>
  import Nav from "./Nav";
  import Top from "./Top";
	export default {
		name: "Home",
		components: {
		  Nav,
          Top
		},
	}
</script>

<style scoped>

</style>
