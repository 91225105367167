<template>
  <van-tabbar v-model="active">
    <van-tabbar-item name="anchor" icon="more" :to="{name: 'anchor'}">用户</van-tabbar-item>
    <van-tabbar-item name="data" icon="cash-back-record" :to="{name: 'data'}">收益</van-tabbar-item>
    <van-tabbar-item name="mine" icon="contact" :to="{name: 'mine'}">我的</van-tabbar-item>
  </van-tabbar>
</template>
<script>
  export default {
    name: 'Nav',
    data() {
      return {
        active: 0
      }
    },
    async created() {
	    let routeName = this.$route.name;
	    switch (routeName) {
        case 'anchor':
	        this.active = 'anchor';
	        break;
		case 'mine':
        	this.active = 'mine';
        	break;
        case 'data':
         	this.active = 'data';
         	break;
	    }
    },
  }
</script>
