<template>
  <div>
    <van-nav-bar
      title="标题"
      right-text="退出"
      left-arrow
      @click-right="Logout"
    />
  </div>
</template>

<script>
	export default {
		name: "Top",
    methods:{
	    Logout() {
	    	console.log(111)
	    }
    }

	}
</script>

<style scoped>

</style>
